@import "src/scss/mixins";

.c-cardspace {
	&__wrapper {
		position: fixed;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		overflow: hidden;

		color: white;

		//background: #1d1d1d url(../../assets/background-grid.png) 0 0;
		background: #1d1d1d url(../../assets/background-grid.png) 50% 50%;
		touch-action: none;

		//background: red;
		//transform: scale(0.3);
	}

	&.is-moving {
		.c-card {
			border: 20px solid red;
			pointer-events: none;
		}
	}
}
