@import "scss/mixins";

.logo {
	position: fixed;

	pointer-events: none;
	z-index: 101;
	left: 0;
	top: 0;
	right: 0;
	padding: 20px;

	background-image: linear-gradient(180deg, rgba(29, 29, 29, 0.4) 30%, rgba(29, 29, 29, 0) 100%);
	transition: transform 0.3s;

	user-select: none;
	-webkit-user-select: none;

	img {
		width: 274px;
		height: auto;
	}
}
.logohidden {
	transform: translateY(-100%);
}

.bottomlogo {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	padding: 20px;
	pointer-events: none;
	z-index: 101;

	user-select: none;
	-webkit-user-select: none;

	transition: transform 0.3s;

	text-align: right;
	background-image: linear-gradient(0deg, rgba(29, 29, 29, 0.4) 30%, rgba(29, 29, 29, 0) 100%);

	img {
		width: 170px;
		height: auto;
	}
}
.bottomlogohidden {
	transform: translateY(100%);
}

@media (max-width: 1100px) {
	.logo img {
		width: 179px;
	}
	.bottomlogo img {
		width: 140px;
	}
}

@media (max-width: 670px) {
	.bottomlogo img {
		width: 110px;
	}
}

.buttonsHolder {
	position: fixed;
	top: 20px;
	right: 20px;
	display: flex;
	z-index: 101;

	button + button {
		margin-left: 16px;
	}
}

.butCenter {
	@include remove-appearance;
	position: relative;
	font-family: "Lato", sans-serif;
	text-transform: uppercase;
	font-size: 18px;
	color: #ffffff;
	letter-spacing: 0;
	background: black;
	cursor: pointer;
	height: 42px;
	padding: 0 15px;
	display: flex;
	align-items: center;
	line-height: 1em;
	border: 1px solid white;
	transition: all 0.2s;
	padding-left: 40px;
	user-select: none;
	-webkit-user-select: none;
	&:hover {
		background: #666;
	}
	&:active {
		background: #222;
	}
	&:before {
		content: "";
		display: block;
		width: 26px;
		height: 26px;
		background: url(./assets/icons/icon-center.svg) 50% 50% no-repeat;
		margin-right: 10px;
		position: absolute;
		left: 6px;
		top: 6px;
	}
}

.butContribute {
	@include remove-appearance;
	position: relative;
	font-family: "Lato", sans-serif;
	text-transform: uppercase;
	font-size: 18px;
	color: #ffffff;
	letter-spacing: 0;
	background: black;
	cursor: pointer;
	height: 42px;
	padding: 0 15px;
	display: flex;
	align-items: center;
	line-height: 1em;
	border: 1px solid white;
	transition: all 0.2s;
	padding-left: 40px;
	user-select: none;
	-webkit-user-select: none;
	&:hover {
		background: #666;
	}
	&:active {
		background: #222;
	}
	&:before {
		content: "";
		display: block;
		width: 26px;
		height: 26px;
		background: url(./assets/icons/icon-contribute.svg) 50% 50% no-repeat;
		margin-right: 10px;
		position: absolute;
		left: 6px;
		top: 6px;
	}
}

.onboarding {
	background-image: linear-gradient(133deg, rgba(#fd1d46, 0.9) 0%, rgba(#7f11a5, 0.9) 100%, rgba(#7910aa, 0.9) 100%);
	border-radius: 30px;
	font-size: 15px;
	line-height: 1;
	padding: 20px 65px;
	position: fixed;
	left: 50%;
	bottom: -60px;
	transform: translateX(-50%);
	color: white;
	opacity: 0;

	animation: onboarding-in 1.4s cubic-bezier(0.33, 0.29, 0.18, 1.28);
	animation-fill-mode: forwards;
	animation-delay: 0s;

	z-index: 2000;
	pointer-events: none;

	@media (max-width: 550px) {
		display: none;
	}
}

.onboardingDismissed {
	opacity: 0;
	transform: translate(-50%, 100px);
	transition: all 0.7s;
}

@media (max-width: 670px) {
	.butCenter {
		width: 34px;
		height: 34px;
		overflow: hidden;
		color: rgba(white, 0);
		padding-left: 0;
		&:before {
			left: 3px;
			top: 3px;
		}
	}
	.butContribute {
		width: 34px;
		height: 34px;
		overflow: hidden;
		color: rgba(white, 0);
		padding-left: 0;
		&:before {
			left: 4px;
			top: 2px;
		}
	}
}

@keyframes onboarding-in {
	0% {
		bottom: -60px;
		opacity: 0;
	}
	100% {
		bottom: 40px;
		opacity: 1;
	}
}
