@import "src/scss/mixins";

.c-slideshow {
	.content-holder {
		position: unset;
	}

	&__curl {
		position: absolute;
		right: 0;
		bottom: -2px;
	}
	&__num {
		font-weight: 900;
		color: white;
		position: absolute;
		bottom: 20px;
		right: 20px;
		text-align: right;
		letter-spacing: 2px;
		font-size: 12px;

		span {
			padding: 0 5px;
		}
	}

	.slick-arrow {
		@include remove-appearance;
		//background: red;
		width: 50px;
		height: 50px;
		display: block;
		text-indent: -200px;
		overflow: hidden;

		position: absolute;
		top: 0;
		cursor: pointer;
		transition: all 0.1s;

		&:hover {
			opacity: 0.7;
		}

		&.slick-prev {
			left: -100px;
			background: url(../../assets/arrow-left.svg) 0% 50% no-repeat;
		}
		&.slick-next {
			right: -100px;
			background: url(../../assets/arrow-right.svg) 100% 50% no-repeat;
		}

		@media (max-width: 1140px) {
			&.slick-prev {
				left: 10px;
				z-index: 1;
			}
			&.slick-next {
				right: 10px;
			}
		}
	}

	&__image-wrap {
		position: relative;
		margin-bottom: 20px;
		overflow: hidden;
		&:before {
			content: "";
			padding-top: percentage(768/1024);
			width: 100%;
			display: block;
			background: black;
		}
		img {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			object-fit: contain;
			display: block;

			&.c-slideshow__image-bg {
				object-fit: cover;
				filter: blur(15px);
				opacity: 0.5;
			}
		}
	}
}
