@import "src/scss/mixins";
@import "src/scss/typography";
@import "src/scss/layout";

.c-story {
	//max-height: 100vh;
	&__header {
		@extend .content-holder__padded;
		@extend h2;
		@extend .ts-background-text;
		> span {
			@extend h2;
			@extend .ts-background-text;
		}

		@media (max-width: 670px) {
			margin-right: 30px;
			margin-left: 30px;
			margin-top: 40px;
		}
	}
	&__section-header {
		@extend h3;
		@extend .ts-background-text;
		text-align: left;
		margin-bottom: 20px;

		> span {
			@extend h3;
			@extend .ts-background-text;
		}
	}
	&__intro {
		@extend .content-holder__padded;
		@extend p.intro;
	}

	&__gallery {
		.c-story__section-header {
			@extend .content-holder__padded;
		}
	}

	&__image {
		.c-story__section-header {
			@extend .content-holder__padded;
		}
	}

	&__image-wrap {
		margin-bottom: 15px;
		position: relative;
		img {
			width: 100%;
			height: auto;
		}
	}
	&__image-desc {
		display: flex;

		&-name {
			//@extend .content-holder__padded;
			@extend h4;
			width: 35%;
			font-weight: bold;
			color: #ffffff;
			letter-spacing: 0;
			padding-right: 20px;
		}
		&-content {
			//@extend .content-holder__padded;
			width: 65%;
			@extend p;
			overflow-wrap: break-word;
			&:only-child {
				width: auto;
				@extend .content-holder__padded;
			}
		}

		@media (max-width: 670px) {
			display: block;
			> div {
				width: auto;
			}
		}
	}

	&__video-wrap {
		margin-bottom: 15px;
		video {
			width: 100%;
			height: auto;
		}
	}

	&__text {
		@extend .content-holder__padded;
	}

	section {
		margin-bottom: 50px;
		&:last-child {
			margin-bottom: 0;
		}
		@media (max-width: 1100px) {
			margin-bottom: 40px;
		}
		@media (max-width: 670px) {
			margin-bottom: 30px;
		}
	}
}
