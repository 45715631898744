@import "scss/reset";
@import "scss/config";
@import "scss/layout";
@import "scss/typography";
@import "scss/preloader";
@import "../node_modules/slick-carousel/slick/slick.css";
@import "components/Card/Card";

.card-space-default {
}

.card-space-notready {
	opacity: 0;
	//opacity: 1;
	transform: scale(1.3);
	//transform: scale(1);
	transition: 1s;
	transform-origin: center center;
}
.card-space-ready {
	opacity: 1;
	transform: scale(1);
	//transition: all 3s ease-out, opacity 3s ease-in;
	transition: all 1.5s ease-out, opacity 1.5s ease-in;
}
.card-space-done {
	transition: none !important;
}
