@import "src/scss/mixins";
@import "src/scss/typography";
@import "src/scss/layout";

$color-slideshow: #7a10aa;
$color-story: #de3971;
$color-quote: #ad2c9a;
$color-video: #ba2f7e;

.c-card {
	user-select: none;
	-webkit-user-drag: none;

	position: absolute;
	background: black;
	//border: 1px solid white;
	overflow: hidden;
	cursor: pointer;
	opacity: 0.8;
	transition: all 0.5s, background 0.2s, transition 0.3s;
	transform: translate(-50%, -50%) translateZ(0);
	//box-shadow: 2px 2px 20px rgba(black, 0.4);

	&--pinned {
		//animation: pinned-initial-animation 2.5s 6 ease-in-out;
		//animation-fill-mode: forwards;
		//animation-delay: 2s;
	}

	&__preloader {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 200;
		background: black;

		//transition: opacity 1.5s;
		//transition-delay: 1s;
		pointer-events: none;
		//
		//&.fadeout {
		//	opacity: 0;
		//	transition: opacity 1.5s;
		//	transition-delay: 1s;
		//	pointer-events: none;
		//}

		&.fadeout {
			animation: preloader-fadeout 1.5s;
			animation-delay: 0s;
			animation-fill-mode: forwards;
		}

		img {
			position: absolute;
			left: 50%;
			top: 0;
			transform: translateX(-50%);
			height: 100%;
			width: auto;
			opacity: 1;
			filter: blur(10px);
		}
	}

	img {
		user-select: none;
		-webkit-user-drag: none;
	}

	&--quote {
		opacity: 1;
		background: rgba(black, 0.7);
		overflow: hidden !important;
	}

	&--video {
		//width: 500px;
	}

	&.is-hovered {
		background: black;
		opacity: 1;
		//z-index: 110 !important;
		transform: translate(-50%, -50%) translateZ(0);
		//box-shadow: 10px 10px 20px rgba(black, 0.3);
	}

	&.is-active {
		//z-index: 111 !;
		//z-index: 0;
		opacity: 1;
		overflow-y: auto;
		height: auto;
		//max-height: 3000px;
		.content-holder {
			padding-bottom: 50px;
		}

		&.c-card--quote {
			width: 500px;
		}

		&.c-card--photo {
			width: 100vw;
			height: 100vh;
			//top: 0 !important;
			//left: 0 !important;
			background: rgba(#1d1d1d, 0.9);
		}

		&.c-card--slideshow {
			width: 100vw;
			height: 100vh;
			//top: 0 !important;
			//left: 0 !important;
			background: rgba(#1d1d1d, 0.9);
		}

		&.c-card--video {
			width: 100vw;
			height: 100vh;
			//top: 0 !important;
			//left: 0 !important;
			background: rgba(#1d1d1d, 0.9);
		}

		&.c-card--story {
			width: 100vw;
			height: 100vh;
			//top: 0 !important;
			//left: 0 !important;
			background: #1d1d1d;
		}
	}

	&__icon-wrap {
		position: absolute;
		bottom: 0;
		right: 0;
		width: 60px;
		height: 60px;
		z-index: 20;
		//overflow: hidden;

		img {
			position: absolute;
			right: 4px;
			bottom: 4px;
			z-index: 1;
		}

		&:before {
			content: "";
			display: block;
			position: absolute;
			right: 0;
			bottom: 0;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 0 60px 60px;
			border-color: transparent transparent #007bff transparent;
		}

		&-photo {
			&:before {
				border-color: transparent transparent $color-slideshow transparent;
			}
		}

		&-quote {
			&:before {
				border-color: transparent transparent $color-quote transparent;
			}
		}

		&-slideshow {
			&:before {
				border-color: transparent transparent $color-slideshow transparent;
			}
		}

		&-story {
			&:before {
				border-color: transparent transparent $color-story transparent;
			}
		}

		&-video {
			&:before {
				border-color: transparent transparent $color-video transparent;
			}
		}
	}

	&--photo {
		> .content-holder {
			padding-top: 35px;
		}
	}

	&__photo-year {
		font-weight: 900;
		font-size: 14px;
		color: #ffffff;
		letter-spacing: 1.31px;
		text-align: center;
		margin-bottom: 18px;
	}

	&__photo-img {
		position: relative;
		margin-bottom: 20px;
		overflow: hidden;

		&:before {
			content: "";
			padding-top: percentage(768/1024);
			width: 100%;
			display: block;
			background: black;
		}

		img {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			object-fit: contain;

			&.c-card__photo-img-bg {
				object-fit: cover;
				filter: blur(15px);
				opacity: 0.5;
			}
		}
	}

	&__photo-bg {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			display: block;
			opacity: 1;
			z-index: 10;
			position: absolute;
			transition: transform 0.3s;

			@include variant(".is-hovered") {
				transform: scale(1.1);
			}
		}

		video {
			width: 100%;
			height: 100%;
			object-fit: cover;
			display: block;
			position: absolute;
			left: 0;
			top: 0;
			//opacity: 0;
			z-index: 9;

			@include variant(".is-hovered") {
				transform: scale(1.1);
			}
		}
	}

	&__photo-content {
		@extend .content-holder__padded;
		display: flex;

		&-name {
			@extend h3;
			width: 35%;
			font-weight: bold;
			color: #ffffff;
			letter-spacing: 0;
			padding-right: 20px;
		}

		&-desc {
			width: 65%;
			@extend p;
			overflow-wrap: break-word;

			&:only-child {
				width: 100%;
			}
		}

		@media (max-width: 670px) {
			display: block;
			> div {
				width: auto;
			}
		}
	}

	&--slideshow {
		> .content-holder {
			padding-top: 35px;
		}
	}

	&__slideshow-item {
		img {
			width: 100%;
			height: auto;
		}
	}

	&--video {
		> .content-holder {
			padding-top: 35px;
		}
	}

	&__video-wrap {
		position: relative;
		margin-bottom: 15px;

		video {
			width: 100%;
			height: auto;
		}
	}

	&__video-play {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		@include remove-appearance;
		background: rgba(#1d1d1d, 0.7);
		cursor: pointer;

		svg {
			width: 60px;
			height: auto;
		}

		#video-play {
			fill: rgba(white, 0.3);
			transition: all 0.25s;
		}

		&:hover {
			#video-play {
				fill: rgba(white, 0.6);
			}
		}
	}

	&--quote {
		display: flex;
		align-items: stretch;
		color: white;
		cursor: zoom-in;

		&.is-active {
			background: white;
			color: #1d1d1d;
			cursor: zoom-out;
			//min-height: 250px;
			//max-height: 500px;
		}
	}

	&__quote-controls {
		position: absolute;
		right: 0;
		top: 0;
		opacity: 0;
		transform: scale(0.5);
		transition: all 0.25s;

		@include variant(".is-hovered >") {
			opacity: 1;
			transform: scale(1);
		}
		@include variant(".is-active >") {
			opacity: 1;
			transform: scale(1);
		}

		button {
			@include remove-appearance;
			cursor: pointer;
			padding: 10px;

			&:hover {
				opacity: 0.8;
			}
		}
	}

	&__quote-wrap {
		padding: 15px;
		padding-bottom: 20px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	&__quote-name {
		@extend h4;
		font-weight: 600;
		letter-spacing: 0;
		position: relative;
		flex-shrink: 0;

		div {
			background: white;
			background-clip: text;
			-webkit-background-clip: text;
			color: transparent;
			transition: all 0.2s;

			&:nth-child(1) {
				opacity: 0.75;
			}

			&:nth-child(2) {
				position: absolute;
				left: 0;
				top: 0;

				opacity: 0;
				background: linear-gradient(-5deg, #fd1e46 0%, #fd1e46 20%, #7a10aa 100%);
				background-size: contain;
				background-clip: text;
				-webkit-background-clip: text;

				//@include variant(".is-hovered") {
				//	opacity: 1;
				//}
				//@include variant(".is-active") {
				//	opacity: 1;
				//}
			}
		}
	}

	&.is-active,
	&.is-hovered {
		> .c-card__quote-wrap > .c-card__quote-name > div {
			&:nth-child(1) {
				opacity: 0 !important;
			}

			&:nth-child(2) {
				opacity: 1 !important;
			}
		}
	}

	&__quote-year {
		margin-top: auto;
		margin-bottom: 12px;
		font-weight: 900;
		font-size: 12px;
		color: #1d1d1d;
		letter-spacing: 1.12px;
	}
	&__quote-content {
		@extend p;
		font-weight: 300;
		letter-spacing: 0;
		margin-top: auto;
		flex-shrink: 1;
		flex-grow: 1;
		cursor: pointer;

		display: -webkit-box;
		-webkit-line-clamp: 4;
		-webkit-box-orient: vertical;
		overflow: hidden;

		opacity: 0.75;
		transition: all 0.3s;

		&-unlimited {
			padding: 0 15px;
			overflow: visible;
			-webkit-line-clamp: initial;
		}

		@include variant(".is-hovered") {
			opacity: 1;
		}

		@include variant(".is-active") {
			-webkit-line-clamp: initial;
			display: block;
			opacity: 1;

			p,
			ul {
				color: #1d1d1d;
				padding-bottom: 10px;
				&:last-child {
					padding-bottom: 0;
				}
			}
		}
	}

	&--story {
		> .content-holder {
			padding-top: 35px;
		}
	}

	&__hover-title {
		//slideshow and photo cards
		text-align: center;
		position: absolute;
		left: 0;
		width: calc(100% - 20px);
		top: 50%;
		transform: translateY(0%);
		z-index: 11;
		padding-left: 10px;
		padding-right: 10px;
		//z-index: 12;

		@extend h4;

		color: #ffffff;
		letter-spacing: 0;
		text-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
		opacity: 0;
		transition: all 0.25s;

		@include variant(".is-hovered") {
			opacity: 1;
			transform: translateY(-50%);
		}
	}

	&__close {
		position: fixed;
		right: 30px;
		top: 10px;
		z-index: 2000;
		@include remove-appearance;
		cursor: pointer;
		width: 50px;
		height: 50px;
		background: url(../../assets/close.svg) 50% 50% no-repeat;
		text-indent: -200px;
		overflow: hidden;

		&:hover {
			opacity: 0.7;
		}

		@media (max-width: 670px) {
			width: 44px;
			height: 44px;
			right: 5px;
			top: 0;
			background: url(../../assets/close-mobile.svg) 50% 50% no-repeat;
		}
	}

	&__header {
		@extend h2;
		@extend .ts-background-text;
	}

	&__index {
		position: absolute;
		left: 0;
		bottom: 0;
		font-size: 14px;
		font-weight: 900;
		color: red;
		z-index: 1000;
		opacity: 0.9;
	}
}

@keyframes pinned-initial-animation {
	0% {
		box-shadow: 0 0 0px rgba(white, 0);
	}
	50% {
		box-shadow: 0 0 40px rgba(white, 1);
	}
	100% {
		box-shadow: 0 0 0px rgba(white, 0);
	}
	//100% {
	//	box-shadow: 0 0 0px rgba(white, 0);
	//}
}

@keyframes preloader-fadeout {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
