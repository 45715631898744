.wrapper {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 200;
	background: transparent;
	box-sizing: border-box;

	* {
		box-sizing: border-box;
	}
}

.badgeWrap {
	width: 480px;
	max-width: 90vw;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
	overflow: hidden;
}

.loadingWrap {
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 28px 45px;
	position: relative;
	z-index: 1;

	svg {
		width: 100%;
		height: auto;
	}
}

.gradBlock {
	&:before {
		content: "";
		width: 100%;
		padding-bottom: 100%;
		display: block;
		background-image: linear-gradient(90deg, #fd1d46 0%, #7910aa 100%);
	}

	position: absolute;
	left: 50%;
	top: 50%;
	width: 150%;
	transform: translate(-50%, -50%) rotate(0);
	animation: gradblock-animation 3s infinite linear;
}

@keyframes gradblock-animation {
	0% {
		transform: translate(-50%, -50%) rotate(0deg);
	}
	100% {
		transform: translate(-50%, -50%) rotate(359deg);
	}
}
