body,
html {
	width: 100%;
	height: 100%;
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	font-family: Lato, sans-serif;

	box-sizing: border-box;

	background: #1d1d1d url(../assets/background-grid.png) 50% 50%;
	overflow: hidden;
}

html,
body,
* {
	box-sizing: border-box;
}

.content-holder {
	max-width: 900px;
	margin-left: auto;
	margin-right: auto;

	@media (max-width: 940px) {
		width: auto;
		//padding-right: 20px;
		//padding-left: 20px;
	}
}
.content-holder__padded {
	width: auto;
	@media (max-width: 940px) {
		width: auto;
		margin-left: 20px;
		margin-right: 20px;
	}
}

.image-holder {
	max-height: 75vh;
	> img {
		width: 100%;
		height: auto;
	}
}
